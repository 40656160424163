const getFullRoute = async(
    url: string
  ) => {
    const config = useRuntimeConfig();
    const baseUrl = config.public.baseURL as string;
    const setUrl = baseUrl + url;
    return setUrl;
  };
  
  export default getFullRoute;
  