<template>
  <q-dialog
    ref="dialogRef"
    :maximized="createPopUp.$state.maximized"
    :position="createPopUp.$state.position"
    :backdrop-filter="createPopUp.$state.backdropFilter"
    style="z-index: 9999999"
  >
    <component
      :is="createPopUp.$state.currentPopUp"
      @hide_popup="closePopUp"
      @open_signup="openSignUpModal"
      @open_signin="openSignInModal"
      @open_restore_password="openRestorePasswordModal"
    />
  </q-dialog>
</template>

<script setup lang="ts">
import { PopUpServices } from '~/services/PopUp/callPopUp';
import { useDialogPluginComponent, QDialog } from 'quasar';
import {
  BasePopupRegister,
  BasePopupForgot,
  BasePopupLogin,
} from '#components';
import AuthPopUp from '~/services/PopUp/AuthPopUp';
import LoginFlowsPopUp from '~/services/PopUp/LoginFlowsPopUp';

const registerPopup = markRaw(BasePopupRegister);
const forgotPopup = markRaw(BasePopupForgot);
const loginPopup = markRaw(BasePopupLogin);

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } =
  useDialogPluginComponent();

const createPopUp = PopUpServices();

const callPopUp = PopUpServices();

const closePopUp = (state: 'ok' | 'ca') => {
  createPopUp.$state.currentPopUp = '';
  if (state === 'ok') {
    onDialogOK();
  } else if (state === 'ca') {
    onDialogCancel();
  } else {
    onDialogHide();
  }
};

const openSignUpModal = () => {
  onDialogCancel();
  callPopUp.callPopUp({
    componentName: registerPopup,
    position: 'standard',
    backdropFilter: 'blur(3px)',
    defaultPopupComponent: AuthPopUp,
  });
};

const openSignInModal = () => {
  onDialogCancel();
  callPopUp.callPopUp({
    componentName: loginPopup,
    position: 'standard',
    backdropFilter: 'blur(3px)',
    defaultPopupComponent: LoginFlowsPopUp,
  });
};

const openRestorePasswordModal = () => {
  onDialogCancel();
  callPopUp.callPopUp({
    componentName: forgotPopup,
    position: 'standard',
    backdropFilter: 'blur(3px)',
    defaultPopupComponent: AuthPopUp,
  });
};

onMounted(() => {
  createPopUp.$state.popUpRef = dialogRef.value;
});
</script>

<style lang="scss"></style>
