<template>
  <q-dialog
    ref="dialogRef"
    :maximized="createPopUp.$state.maximized"
    :position="createPopUp.$state.position"
    :backdrop-filter="createPopUp.$state.backdropFilter"
    style="z-index: 9999999"
  >
    <component
      :is="createPopUp.$state.currentPopUp"
      @hide_popup="closePopUp"
      @open_signin="openSignInModal"
      @open_restore_password="openRestorePasswordModal"
      @email_flow="emailFlow"
      @google_flow="googleFlow"
      @linkedin_flow="linkedinFlow"
    />
  </q-dialog>
</template>

<script setup lang="ts">
import getRoute from '~/utilities/configs/apiMap';
import getFullRoute from '~/utilities/helpers/getFullRoute';
import { apiPost, apiGet } from '~/services/api';
import { markRaw } from 'vue';
import { PopUpServices } from '~/services/PopUp/callPopUp';
import { useDialogPluginComponent, QDialog } from 'quasar';
import {
  BasePopupForgot,
  BasePopupLogin,
  BasePopupRegisterEmail,
} from '#components';
import AuthPopUp from '~/services/PopUp/AuthPopUp';
import LoginFlowsPopUp from '~/services/PopUp/LoginFlowsPopUp';

const forgotPopup = markRaw(BasePopupForgot);
const loginPopup = markRaw(BasePopupLogin);
const emailFlowPopup = markRaw(BasePopupRegisterEmail);

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } =
  useDialogPluginComponent();

const createPopUp = PopUpServices();

const callPopUp = PopUpServices();

const closePopUp = (state: 'ok' | 'ca') => {
  createPopUp.$state.currentPopUp = '';
  if (state === 'ok') {
    onDialogOK();
  } else if (state === 'ca') {
    onDialogCancel();
  } else {
    onDialogHide();
  }
};

const openSignInModal = () => {
  onDialogCancel();
  callPopUp.callPopUp({
    componentName: loginPopup,
    position: 'standard',
    backdropFilter: 'blur(3px)',
    defaultPopupComponent: LoginFlowsPopUp,
  });
};

const openRestorePasswordModal = () => {
  onDialogCancel();
  callPopUp.callPopUp({
    componentName: forgotPopup,
    position: 'standard',
    backdropFilter: 'blur(3px)',
    defaultPopupComponent: AuthPopUp,
  });
};

const emailFlow = () => {
  onDialogCancel();
  callPopUp.callPopUp({
    componentName: emailFlowPopup,
    position: 'standard',
    backdropFilter: 'blur(3px)',
    defaultPopupComponent: AuthPopUp,
  });
};

const googleFlow = async () => {
  onDialogCancel();
  const url = getRoute({ endpont: 'get_google_auth' });
  const fullUrl = await getFullRoute(url);
  console.log(fullUrl);
  window.location.href = fullUrl;
};

const linkedinFlow = async () => {
  onDialogCancel();
  const url = getRoute({ endpont: 'get_linkedin_auth' });
  const fullUrl = await getFullRoute(url);
  console.log(fullUrl);
  window.location.href = fullUrl;
};

onMounted(() => {
  createPopUp.$state.popUpRef = dialogRef.value;
});
</script>

<style lang="scss"></style>
